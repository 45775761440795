import FaceHide from './FaceHide';
import './App.css';
import Vid from './Vid';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p> I'm sorry</p>
        {FaceHide()}
        {Vid()}

      </header>
    </div>
  );
}

export default App;
